<template>
    <main>
        <section class="row">
            <div
                class="col-8 col-md-offset-2 col-md-8 col-offset-2 col-sm-offset-0 col-sm mb-1 my-1"
            >
                <h1 class="h1 main__title">Карьера в BRANDSHOP</h1>
                <p class="p1 main-text">
                    Мы всегда находимся в поисках новых сотрудников, которые
                    могли бы укрепить и пополнить нашу команду. Если вы знаете,
                    чем можете быть полезны BRANDSHOP, готовы привносить что-то
                    новое, что поможет улучшить нашу работу, присылайте рассказ
                    о себе в свободной форме на электронную почту, указанную под
                    вакансией. Не забудьте приложить резюме и, желательно,
                    ссылку на ваш профиль в социальных сетях либо фотографию.
                </p>
            </div>
        </section>
        <section class="row__full">
            <div class="career__features ">
                <CareerFeature
                    v-for="feature in features"
                    v-bind:key="feature.id"
                    v-bind:feature="feature"
                />
            </div>
        </section>
        <section class="row">
            <div
                class="col-md-8 col-offset-2 col-8 col-md-offset-2 col-sm-offset-0 col-sm"
            >
                <CareerDescription
                    v-for="description of descriptionList"
                    v-bind:key="description.id"
                    v-bind:description="description"
                />
                <div
                    key="moreBtn"
                    v-if="careerDescriptions.length > 3 && !viewAllDescriptions"
                    class="center flex"
                >
                    <Button
                        class="btn__more"
                        v-on:click="viewAllDescriptions = !viewAllDescriptions"
                        value="Смотреть все вакансии"
                    />
                </div>
                <div
                    key="lessBtn"
                    v-if="careerDescriptions.length > 3 && viewAllDescriptions"
                    class="center flex"
                >
                    <Button
                        class="btn__more"
                        v-on:click="viewAllDescriptions = !viewAllDescriptions"
                        value="Скрыть вакансии"
                    />
                </div>
            </div>
        </section>
        <section class="row">
            <div
                class="other col-offset-2 col-8 col-md-8 col-md-offset-2 col-sm col-sm-offset-0"
            >
                <h3 class="h3">Не нашли, что искали?</h3>
                <p class="p1 text-muted">
                    Мы всегда в поиске талантливых специалистов и рады
                    рассмотреть каждого соискателя. Если вы знаете, чем бы вы
                    могли быть нам полезны, пишите на
                    <a href="mailto:job@brandshop.ru">job@brandshop.ru</a>
                </p>
            </div>
        </section>
    </main>
</template>

<script>
import CareerFeature from "@/components/CareerFeature.vue";
import CareerDescription from "@/components/CareerDescription.vue";
import Button from "@/components/Button.vue";

export default {
    components: {
        CareerFeature,
        CareerDescription,
        Button
    },
    data() {
        return {
            firstThreeDescriptions: [],
            viewAllDescriptions: true,
            careerDescriptions: [
                {
                    id: 2,
                    title: "Дизайнер UX/UI",
                    subTitle: "Удаленная работа",
                    content: {
                        responsibility: "Обязанности:",
                        respList: [
                            "Разрабатывать интерфейсы, максимально удобные для клиента магазина;",
                            "Создавать прототипы, проводить юзабилити-тестирования;",
                            "Контролировать внедрение макета на всех этапах(верстка, программирование).",
                        ],
                        requirements: "Требования:",
                        reqList: [
                            "Опыт создания web-дизайна в e-commerce;",
                            "Понимание базовых принципов HTML-верстки, возможностей и ограничений CSS, JS(Если что разработчики помогут);",
                            "Знание Figma и основ композиций;",
                            "Умение строить UX гипотезы на основе данных(рефы и аналитика);",
                            "Умение разрабатывать UI интерфейфы на основе UX;",
                            "Понимание гайдлайнов iOS и Android;",
                            "Умение сходу понимать бизнес-задачу, корректно оценивать объем работ и время необходимое на выполнение задачи;",
                            "Умение корректно доказывать свою точку зрения, подтверждая ее примерами;",
                            "Умение грамотно изъясняться на русском языке;",
                            "Заинтересованность и высокая инициативность в процессе."
                        ],
                        offers: "Мы предлагаем:",
                        offersList: [
                            "Стабильная зарплата;",
                            "Хорошая развивающаяся команда;",
                            "График работы 5/2 с 10.00 до 19.00."
                        ]
                    },
                    email: 'job@brandshop.ru',
                    emailDescription: 'Отклики без ссылок на работы и профиль рассматриваться не будут! Присылайте ваше резюме вместе с ссылками на работы и профилем в соцсетях(VK, Instagram, The Facebook) ) на '
                },
                {
                    id: 3,
                    title: "Курьер",
                    subTitle: "",
                    content: {
                        responsibility: "Обязанности:",
                        respList: [
                            "бережная и аккуратная доставка заказов (адреса в пределах МКАД либо в пределах 2 км от метро за МКАД).",
                        ],
                        requirements: "Требования:",
                        reqList: [
                            "отличное ориентирование по городу;",
                            "идеальная пунктуальность;",
                            "отличный русский язык."
                        ],
                        offers: "Мы предлагаем:",
                        offersList: [
                            "сменный график работы;",
                            "один адрес = 400 руб.;",
                            "доплата за объемные заказы"
                        ]
                    },
                    email: 'sv@brandshop.ru',
                    emailDescription: 'Уважаемые соискатели‚ пожалуйста цените ваше и наше время. Присылайте отклики только в соответствии с описанием вакансии.'
                },
                {
                    id: 1,
                    title: "Фотограф",
                    subTitle: "Фотостудия",
                    content: {
                        responsibility: "Обязанности:",
                        respList: [
                            "Съёмка одежды, обуви и аксессуаров для каталога онлайн-магазина;",
                            "Предметная съёмка;",
                            "Соблюдение требований к готовым материалам;",
                        ],
                        requirements: "Требования:",
                        reqList: [
                            "Знание и владение светотехникой и фототехникой;",
                            "Навыки пользования Adobe Photoshop, Adobe Bridge, Capture one;",
                            "Знание основ цветокоррекции и фотографии;",
                            "Важные факторы: следование гайдлайнам и стандартам качества, понимание дедлайна;",
                            "Опыт работы по данным направлениям будет весомым преимуществом;",
                        ],
                        offers: "Мы предлагаем:",
                        offersList: [
                            "Оформление по ТК РФ;",
                            "Офис на м. Савеловская;",
                            "График работы - 5/2 c 10:00 до 19:00;",
                            "Заработная плата по итогам собеседования.",
                        ]
                    },
                    email: 'mb@brandshop.ru',
                    emailDescription: "Присылайте резюме, портфолио и ссылки на свои аккаунты в социальных сетях на ",
                    additionally: ""
                },{
                    id: 5,
                    title: "Кладовщик",
                    subTitle: "Работа на складе",
                    content: {
                        responsibility: "Обязанности:",
                        respList: [
                            "Разгрузка и размещение товаров на складе;",
                            "Подбор товаров по накладным;",
                            "Переупковка товаров из торгового зала;"
                        ],
                        requirements: "Требования:",
                        reqList: [
                            "Отличная адаптивность к большим нагрузкам и многозадачности;",
                            "Желателен опыт работы на складе с одеждой и обувью;",
                            "Понимание складской логистики.",
                        ],
                        offers: "Мы предлагаем:",
                        offersList: [
                            "Оформление по ТК РФ;",
                            "Официальная зарплата;",
                            "Место работы ул. Каретный ряд, д. 8;",
                            "Отличный коллектив и лояльное руководство;",
                            "Сменный график работы c 10:00 до 22:00;",
                            "Заработанная плата на испытательном сроке 60 000 рублей, после плановое повышение до 70 000 рублей.",
                        ]
                    },
                    email: "nb@brandshop.ru",
                    emailDescription: "Если вы узнали себя, то отправляйте резюме на ",
                    additionally: "Уважаемые соискатели‚ пожалуйста цените ваше и наше время. Присылайте отклики только в соответствии с описанием вакансии."
                }
            ],
            features: [
                {
                    id: 1,
                    title: "Профессиональная среда",
                    text:
                        "Отличный дружный коллектив высококлассных специалистов, у которых есть чему поучиться."
                },
                {
                    id: 2,
                    title: "Современные технологии",
                    text:
                        "Мы не идем на поводу у сиюминутных трендов, отдавая предпочтение только самым полезным решениям для клиентов."
                },
                {
                    id: 3,
                    title: "Карьерный рост",
                    text:
                        "Интересные и амбициозные задачи помогут вам стать лучшим в своем деле."
                }
            ]
        };
    },
    methods: {
        firstThreeDescs() {
            this.firstThreeDescriptions = this.careerDescriptions.filter(
                (item, i) => i < 3
            );
        }
    },
    mounted() {
        this.firstThreeDescs();
    },
    computed: {
        descriptionList: function () {
            return this.viewAllDescriptions
                ? this.careerDescriptions
                : this.firstThreeDescriptions;
        }
    }
};
</script>
